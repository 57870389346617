<template>
    <div class="messageCard">

        <div class="left">
            <van-image width="50px" height="50px" :src="require('@/assets/all_Icon/drawable-xxhdpi/kefu_header.png')" />
        </div>
        <div class="right">
            <div class="name">
                <span class="name_sp1">客服</span>
                <span class="name_sp2">2022-09-05</span>
            </div>
            <div class="msg ellipsis">您好...</div>
        </div>


    </div>
</template>

<script>

export default {
    // props:['item'],
    components: { },
    data() {
        return {
            
        };
    },
    created() {
       
    },
    methods: {
       
    },
};
</script>

<style lang="less" scoped>
.messageCard{
    display: flex;
    padding: 0 14px;
    margin-bottom: 20px;

    .left{

        .van-image{
            margin-left: 20px;
        }
    }

    .right{
        flex: 1;
        margin-left: 15px;

        .name{
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            .name_sp1{
                font-size: 15px;
                color: #141414;

            }
            .name_sp2{
                font-size: 12px;
                color: #999999;
            }
        }

        .msg{
            font-size: 12px;
            color: #666666;
            width: 260px;
        }
    }
    
}
</style>
