<template>
    <div class="messageCard">

        <div class="top">
            <div class="avatar">
                <van-image width="32px" height="32px" :src="msgType[item.type].avatar" />
                <div class="title">{{msgType[item.type].type}}消息</div>
            </div>
            <div class="date">{{item.intime}}</div>
        </div>
        <div class="bottom">
            {{item.content}}
        </div>


    </div>
</template>

<script>

export default {
    props:['item'],
    components: { },
    data() {
        return {
            // 类型(item.type)：1、订单 2、支付 3、直播 4、问答
            msgType:[ //消息对应的图片和类型
                {
                   avatar:'',
                   type:''
                },
                {
                    avatar: require('@/assets/all_Icon/drawable-xxhdpi/message_order.png'),
                    type:'订单'
                },
                {
                    avatar: require('@/assets/all_Icon/drawable-xxhdpi/message_pay.png'),
                    type:'支付'
                },
                {
                    avatar: require('@/assets/all_Icon/drawable-xxhdpi/message_live.png'),
                    type:'直播'
                },
                {
                    avatar: require('@/assets/all_Icon/drawable-xxhdpi/message_question.png'),
                    type:'问答'
                }
            ],
        };
    },
    created() {
       
    },
    methods: {
       
    },
};
</script>

<style lang="less" scoped>
.messageCard{
    // background-color: red;
    padding: 0 14px;
    margin-bottom: 20px;

    .top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .avatar{
            display: flex;
            align-items: center;

            .title{
                font-size: 15px;
                color: #141414;
                margin-left: 10px;
            }
        }

        .date{
            font-size: 12px;
            color: #999999;
        }
    }

    .bottom{
        border-bottom: 0.5px solid #ccc;
        font-size: 12px;
        color: #666666;
        margin-left: 40px;
        padding-bottom: 17px;
    }
}
</style>
