<template>
    <div class="message">
        <!-- 头部 -->
        <van-nav-bar class="navBar" fixed :placeholder="true" @click-left="$router.go(-1)">
            <template #left class="left">
                <van-icon name="arrow-left" size="22" color="#141414" />
            </template>
            <template #title>
                <span class="title" style="font-size:17px;font-weight:500;">消息</span>
            </template>
        </van-nav-bar>

        <!-- tab -->
        <van-tabs v-model="tabActive" sticky offset-top="46px" swipeable animated color="#3557E8" title-active-color="#3557E8">

            <van-tab title="通知消息">
                <van-pull-refresh v-model="tongZhiLoading" @refresh="tongZhiRefresh">
                    <van-list class="tongZhiList" v-model="tongZhiDownloading" :finished="tongZhifinished" finished-text="没有更多了" offset="50" :immediate-check="false" @load="tongZhiLoad" >

                        <div style="height:20px;"></div>
                        <messageCard :item="item" v-for="(item,i) in tongZhiList" :key="i" />
                        <div v-if="tongZhiList.length <= 6" style="height:50vh;"></div>
                    </van-list>
                </van-pull-refresh>
            </van-tab>

            <!-- <van-tab title="客服消息">
                <van-pull-refresh v-model="keFuLoading" @refresh="keFuRefresh">
                    <van-list class="keFuList" v-model="keFuDownloading" :finished="keFuifinished" finished-text="没有更多了" offset="50" :immediate-check="false" @load="keFuLoad" >

                        <div style="height:20px;"></div>
                        <div @click="to53kefu">
                            <messageCard2 />
                        </div>
                        <div style="height:50vh;"></div>
                    </van-list>
                </van-pull-refresh>
            </van-tab> -->
        </van-tabs>

    </div>
</template>

<script>
import messageCard from "@/pages/me/components/messageCard";
import messageCard2 from "@/pages/me/components/messageCard2";

import { notificationList } from "@/api/user.js";
export default {
    components: { messageCard, messageCard2},
    data() {
        return {
            tabActive:0,

            // 通知消息
            tongZhiList:[], //通知消息
            tongZhiLoading:false, //刷新Loading
            tongZhiDownloading:false, //下拉加载更多Loading
            tongZhifinished:false, //关闭下拉
            tongZhiCount:0,
            tongZhiPage:0,
            
            // 客服消息
            keFuList:[], //客服消息
            keFuLoading:false, //刷新Loading
            keFuDownloading:false, //下拉加载更多Loading
            keFuifinished:false, //关闭下拉
            keFuCount:0,
            keFuPage:0,
            
        };
    },
    created() {
        this.notificationList()
        this.getKeFuList()
    },
    methods: {
        // 获取通知消息
        notificationList(){
            let data =new FormData()
            data.append('page',this.tongZhiPage)

            notificationList(data).then(res =>{
                console.log('获取消息列表----',res)
                if(res.data.code == 0){
                    this.tongZhiList.push(...res.data.data)
                    this.tongZhiCount = res.data.count
                }
                this.tongZhiLoading = false
                this.tongZhiDownloading = false
            })
        },
        // 通知消息刷新
        tongZhiRefresh(){
            this.tongZhiList = []
            this.tongZhifinished = false
            this.tongZhiCount = 0
            this.tongZhiPage = 0
            this.notificationList()
        },
        // 通知消息加载更多
        tongZhiLoad(){
            this.tongZhiPage ++
            console.log('通知消息加载更多----',this.tongZhiPage)
            if(this.tongZhiList.length >= this.tongZhiCount){ 
                return this.tongZhifinished = true
            }
            this.notificationList()
        },


        // 获取客服消息
        getKeFuList(){
            let data =new FormData()
            data.append('page',this.keFuPage)

            notificationList(data).then(res =>{
                console.log('获取客服消息----',res)
                if(res.data.code == 0){
                    this.keFuList.push(...res.data.data)
                    this.keFuCount = res.data.count
                }
                this.keFuLoading = false
                this.keFuDownloading = false
            })
        },
        // 客服消息刷新
        keFuRefresh(){
            this.keFuList = []
            this.keFuifinished = false
            this.keFuCount = 0
            this.keFuPage = 0
            this.getKeFuList()
        },
        // 客服消息加载更多
        keFuLoad(){
            this.keFuPage ++
            console.log('通知消息加载更多----',this.keFuPage)
            if(this.keFuList.length >= this.keFuCount){ 
                return this.keFuifinished = true
            }
            this.getKeFuList()
        },

        // 跳53客服
        to53kefu(){
            window.location.href="http://www.canet.com.cn/kefu/"
        }
    },
};
</script>

<style lang="less" scoped>
.message{
    min-height: 100vh;
    background-color: #f5f5f5;
    
    .tongZhiList, .keFuList{
       
    }
}
</style>
